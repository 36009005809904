<template>
  <div>
    <WaBtn />
    <MainBannerBM />

    <!-- Our Services -->
    <div class="pt-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-af-darkblue">
          Perkhidmatan Kami
        </h1>
        <div class="w-10 h-1 mx-auto bg-af-darkblue"></div>
        <div class="pt-10 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in service"
            :key="i"
            class="pb-6 md:w-1/2 lg:w-1/3 md:p-1"
          >
            <div class="">
              <img :src="item.image" :alt="item.alt" class="md:mx-auto" />
              <div class="pt-5 md:px-5">
                <h1 class="text-xl font-semibold text-left text-af-darkblue">
                  {{ item.alt }}
                </h1>
                <div class="w-10 h-1 mt-3 bg-af-darkblue"></div>
                <p
                  v-html="item.p"
                  class="py-4 text-sm text-left text-gray-700 lg:text-md md:text-left"
                >
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Why Choose Us -->
    <div class="py-10 bg-blue-50">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-af-dblue">
          Mengapa Pilih Active Floor?
        </h1>
        <div class="w-10 h-1 mx-auto bg-af-dblue"></div>
        <div class="pt-10 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in Active_Floor"
            :key="i"
            class="py-1 md:px-1 md:w-1/2 lg:w-1/4"
          >
            <div class="py-6 bg-white rounded-lg boxShadow">
              <img :src="item.image" :alt="item.alt" class="" />
              <div class="md:h-56 lg:h-96 xl:h-80">
                <h1 class="text-lg font-semibold text-center text-af-darkblue">
                  {{ item.alt }}
                </h1>
                <p class="px-2 py-5 text-sm text-center text-gray-700">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Works section -->
    <div class="py-10 bg-af-darkblue">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-white">
          Kerja Kami
        </h1>
        <div class="w-10 h-1 mx-auto bg-white"></div>
        <!-- works section -->
        <div id="works" class="pt-3 lg:py-6">
          <carousel
            :loop="true"
            :autoplay="true"
            :dots="false"
            :autoplaySpeed="3000"
            :autoplayTimeout="3000"
            :responsive="{
              0: { items: 2, nav: false },
              700: { items: 3, nav: false },
              1000: { items: 4, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in brands" :key="i" class="px-1">
              <img :src="item.image" :alt="item.alt" class="rounded-sm" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-af-darkblue">
          Projek Masa Lalu Kami
        </h1>
        <div class="w-10 h-1 mx-auto bg-af-darkblue"></div>
        <!-- <div class="pt-5 lg:w-2/3 lg:mx-auto">
          <Accordion :faq="faq" :focus="false" class="pt-4" />
        </div> -->
        <div class="pt-6 md:flex md:flex-wrap lg:justify-center">
          <div
            v-for="(item, i) in project"
            :key="i"
            class="pt-3 md:w-1/2 md:px-1 md:pt-4 lg:w-1/3"
          >
            <img :src="item.image" :alt="item.alt" />
            <div class="flex justify-between">
              <h1 class="w-1/2 text-sm font-semibold text-center text-gray-900">
                Sebelum
              </h1>
              <h1 class="w-1/2 text-sm font-semibold text-center text-gray-900">
                Selepas
              </h1>
            </div>
            <h1 class="pt-2 text-base font-bold text-af-darkblue">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- client list  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-client.jpg')"
    >
      <div class="container">
        <h2 class="pb-5 text-5xl font-bold text-center text-white">
          Pelanggan Bahagia Kami
        </h2>
        <div class="w-40 h-1 mx-auto bg-white"></div>
        <div
          class="pt-4 text-lg xl:pl-12 md:text-xl custom md:flex md:text-left md:w-5/6 lg:w-3/4 xl:w-2/3 md:mx-auto"
        >
          <div class="text-white">
            <ul class="list-none list-outside md:pr-4 md:list-disc">
              <li>Bank Pelaburan Hong Leong</li>
              <li>Maybank Berhad</li>
              <li>Pejabat Pengurusan Capsquare</li>
              <li>Pejabat Pengurusan Timur Atas Mutiara</li>
              <li>Jolintas Sdn Bhd</li>
              <li>Burgess Rawson Sdn Bhd (M City Residency Jalan Ampang)</li>
              <li>Sutera Kiaramas Mont Kiara Management Office</li>
              <li>Neway Sdn Bhd</li>
            </ul>
          </div>
          <div class="text-white md:pl-4">
            <ul class="list-none list-outside md:list-disc">
              <li>Myeg Sdn Bhd</li>
              <li>Ko Skin</li>
              <li>Ricoh Sdn Bhd</li>
              <li>SHDC Sdn Bhd</li>
              <li>Nirvana Care Sdn Bhd</li>
              <li>Mk Land Saujana Triangle Sdn Bhd</li>
              <li>Shangri-La Hotel KL</li>
              <li>Premier Hotel</li>
              <li>Deleum Bangsar</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10 lg:flex">
      <div class="lg:w-1/2 lg:ml-auto">
        <img src="/images/bg-about.jpg" alt="" class="mx-auto" />
      </div>
      <div class="pt-4 lg:w-1/2 lg:text-left lg:pl-3 lg:pt-0">
        <div class="xl:max-w-xl xl:mr-auto">
          <h1
            class="text-2xl font-semibold text-center lg:pl-3 lg:text-left text-af-darkblue"
          >
            Tentang Kami
          </h1>
          <div class="px-2 pt-4 text-sm">
            <p class="pb-2">
              <b
                >Active Floor Maintenance & Reno ialah pakar pembersihan, penggilapan, pembaikan, pengedap, pemulihan dan penyelenggaraan lantai terkemuka di Malaysia.</b
              >
              Anda boleh bergantung kepada kami untuk hampir semua pembersihan permukaan lantai termasuk marmar, granit, travertine, batu besar, batu tulis, terazo, bata, konkrit dan batu semula jadi.
            </p>
            <p class="pb-2">
              <b
                >Jika anda mempunyai lantai marmar, dinding, meja solek atau pancuran mandian yang telah terukir, telah hilang kilauan dan keindahannya atau memerlukan pembaikan, maka sudah tiba masanya untuk menghubungi kontraktor pemulihan profesional.</b
              >
              Kami boleh menggilap, menapis, membaiki dan memulihkan sepenuhnya marmar, batu kapur, travertine, saturnia atau batu semula jadi lain anda kembali kepada keadaan asalnya.
            </p>
            <p>
              Jika anda mempunyai marmar dengan kemasan matte / diasah, ia juga memerlukan penjagaan dan penyelenggaraan yang betul. Marmar dengan kemasan matte / diasah hendaklah dibersihkan secara berkala dan dimeterai semula oleh seorang profesional.
              <br /><br />
              Di <b>Active Floor Maintenance & Reno</b> kami bukan sahaja melaksanakan perkhidmatan ini, kami juga boleh memberi arahan kepada anda tentang penjagaan yang betul yang perlu anda gunakan dalam membersihkan dan menyelenggara lantai dan permukaan marmar anda.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry form -->
    <div class="py-8 bg-af-darkblue">
      <div class="container">
        <div id="element" class="lg:max-w-3xl lg:mx-auto">
          <div class="pb-4">
            <h1 class="text-3xl font-bold text-center text-white md:text-2xl">
              Hantar Pertanyaan
            </h1>
            <p class="pt-3 text-sm text-white">
              Isi borang di bawah dan kami akan menghubungi anda semula secepat mungkin.
            </p>
          </div>
          <!-- feedback.activamedia.com.sg script begins here -->
          <div id="enquire">
            <iframe
              allowTransparency="true"
              style="min-height: 1000px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5977878.html"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            >
          </div>
          <!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <footer class="py-8">
      <div class="container flex flex-wrap lg:justify-between">
        <div class="w-full lg:w-1/2 lg:pt-8 lg:pr-4">
          <img
            src="/images/main-logo.png"
            alt="Active Floor Maintenance & Reno"
            class="w-1/2 mx-auto md:w-1/3 lg:w-52"
          />
          <div class="py-4">
            <p
              class="text-lg font-semibold leading-tight text-center text-af-darkblue"
            >
              25, Jalan Penggawa 28/2, Bandar Mahkota Cheras, Selangor.
            </p>
            <p class="pt-5 text-base font-bold text-center">
              Hubungi Kami Di :
              <a
                id="call-btn-b1"
                href="tel:+60163330266"
                class="text-gray-900 hover:text-af-darkblue"
                >016-333 0266</a
              >
              |
              <a
                id="call-btn-b2"
                href="https://wa.me/60163330266"
                class="text-gray-900 hover:text-af-darkblue"
                >Whatsapp Kami</a
              >
            </p>
          </div>
          <div class="md:flex md:justify-center md:items-center">
            <div class="pb-3 md:pb-0 md:w-1/4 lg:w-1/3">
              <img
                src="/images/cibd-malaysia.png"
                alt="CIBD MALAYSIA"
                class="pb-2 mx-auto md:pb-0 w-44 md:w-auto"
              />
              <p class="text-sm leading-tight text-center">Syarikat Berdaftar Di Bawah CIDB Malaysia</p>
            </div>
            <div class="md:w-1/4 lg:w-1/3">
              <img
                src="/images/malaysia-logo.png"
                alt="MALAYSIA"
                class="mx-auto w-44 md:w-auto"
              />
              <p class="text-sm leading-tight text-center">Syarikat Berdaftar Di Bawah Kementerian Kewangan Malaysia</p>
            </div>
          </div>
        </div>
        <div class="w-full py-5 lg:w-1/2">
          <div class="py-4 text-center">
            <h1 class="text-lg font-bold">Kawasan Liputan Kami</h1>
            <div class="md:flex">
              <div class="py-2 md:w-1/2">
                <p class="font-bold">Kuala Lumpur</p>
                <div class="flex justify-center">
                  <div class="w-1/2">
                    <ul>
                      <li>Cheras</li>
                      <li>Pandan Indah</li>
                      <li>Pandan Perdana</li>
                      <li>Pandan Cahaya</li>
                      <li>Pandan Jaya</li>
                      <li>Keramat</li>
                      <li>Danau Kota</li>
                      <li>Setapak</li>
                      <li>Wangsa Maju</li>
                      <li>Taman Melawati</li>
                      <li>Sungai Besi</li>
                      <li>Salak Salatan</li>
                      <li>Petaling Jaya</li>
                      <li>Bangsar</li>
                    </ul>
                  </div>
                  <div class="w-1/2">
                    <ul>
                      <li>Damasara</li>
                      <li>Desa Petaling</li>
                      <li>Gombak</li>
                      <li>Jalan Kuching</li>
                      <li>Jalan Ipoh</li>
                      <li>Jinjang</li>
                      <li>Kepong</li>
                      <li>Kuchai Lama</li>
                      <li>Mont Kiara</li>
                      <li>Old Klang Road</li>
                      <li>OUG</li>
                      <li>Segambut</li>
                      <li>Sentul</li>
                      <li>Serdang</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="py-2 md:w-1/2">
                <p class="font-bold">Selangor</p>
                <div class="flex justify-center">
                  <div class="w-1/2">
                    <ul>
                      <li>Ampang</li>
                      <li>Puchong</li>
                      <li>Subang</li>
                      <li>Subang Jaya</li>
                      <li>Shah Alam</li>
                      <li>Bukit Beruntung</li>
                      <li>Rawang</li>
                      <li>Lembah Jaya</li>
                      <li>Semenyih</li>
                      <li>Kajang</li>
                      <li>Bangi</li>
                      <li>Batang Kali</li>
                      <li>Ulu Yam</li>
                      <li>Cyberjaya</li>
                      <li>Banting</li>
                    </ul>
                  </div>
                  <div class="w-1/2">
                    <ul>
                      <li>Kelana Jaya</li>
                      <li>Kota Kemuning</li>
                      <li>Petaling Jaya</li>
                      <li>Selayang</li>
                      <li>Seri Kembangan</li>
                      <li>USJ</li>
                      <li>Balakong</li>
                      <li>Klang</li>
                      <li>Batu Caves</li>
                      <li>Dengkil</li>
                      <li>Hulu Langat</li>
                      <li>Jenjarom</li>
                    </ul>
                    <p class="pt-3 font-bold">Putrajaya</p>
                    <div class="justify-center">
                      <div>
                        <ul>
                          <li>Putrajaya</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs font-medium text-center text-white md:text-sm">
          Laman web diselenggara oleh Activa Media. Hak cipta terpelihara.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import WaBtn from "@/components/WaBtn.vue";
import MainBannerBM from "@/components/MainBannerBM.vue";
import carousel from "vue-owl-carousel";
import Accordion from "@/components/Accordion.vue";

export default {
  components: {
    MainBannerBM,
    carousel,
    Accordion,
    WaBtn
  },
  data() {
    return {
      service: [
        {
          image: "/images/service-1.jpg",
          alt: "Mengilap & Pemulihan Lantai",
          p: "Marmar, Granit, Jubin Porselin, Lantai Getah, Lantai Vinil , Lantai Kayu, Parket, Dek Kayu, Lantai Simen",
        },
        {
          image: "/images/service-2.jpg",
          order: "order-2",
          alt: "Meja Poland / Kaunter Atas",
          p: "Marmar, Granit, Permukaan Pepejal, Meja Kayu",
        },
        {
          image: "/images/service-3.jpg",
          alt: "Pembersihan Lantai",
          p: "Jubin Luaran, Jubin Seramik, Lantai Marmar, Lantai Epoksi, Lantai Permaidani",
        },
        {
          image: "/images/service-4.jpg",
          alt: "Pembersihan Sofa",
          order: "order-2",
          p: "Kulit & Fabrik",
        },
        {
          image: "/images/service-5.jpg",
          alt: "Membaiki Lantai",
          order: "order-2",
          p: "Marmar Sumbing, Lantai Kayu Reput, Calar, Rosak Air dll.",
        },
        {
          image: "/images/service-6.jpg",
          alt: "Pemasangan",
          order: "order-2",
          p: "Parket, Lantai Kayu, Decking Kayu Pepejal - Chengal, Balau, Kayu Merbau, Kayu Jati, Oak Putih dsb. Pelbagai Reka Bentuk - Jalur Mendatar Klasik, Pepenjuru, Kayu Bata atau Tampal Herringbone",
        },
        {
          image: "/images/service-7.jpg",
          alt: "Membersih dan Mengilap Dinding",
          order: "order-2",
          p: "Semua Jenis Dinding",
        },
        {
          image: "/images/service-8.jpg",
          alt: "Pembersihan Lantai Luaran",
          order: "order-2",
          p: "Pembersihan Laluan Teras",
        },
      ],
      Active_Floor: [
        {
          image: "/images/icon-1.png",
          alt: "Lebih 2 Dekad Pengalaman",
          p: "Dengan lebih 2 dekad pengalaman dalam industri pembersihan dan pembasmian kuman, kami memahami bahawa kedua-dua proses memerlukan penambahbaikan dan inovasi yang berterusan untuk memenuhi keperluan pelanggan kami yang sentiasa berubah dan persekitaran tempat kami tinggal.",
        },
        {
          image: "/images/icon-2.png",
          alt: "Harga Berpatutan, Tiada Kompromi pada Kualiti",
          p: "Juruteknik mahir kami semuanya mempunyai pengalaman lebih daripada 20 tahun dengan semua jenis kerja pengilapan",
        },
        {
          image: "/images/icon-3.png",
          alt: "Pakar Terlatih",
          p: "Kami mempunyai pasukan pakar penggilap marmar terlatih yang tidak pernah gagal untuk menarik perhatian pelanggan kami dengan kemahiran serta pengetahuan mereka tentang proses tersebut. Kerja mereka adalah terkemuka dan kerana pengalaman bertahun-tahun, mereka memahami marmar masuk dan keluar. Proses mengilat marmar adalah proses yang teliti dan perlu dikendalikan oleh hanya profesional yang mempunyai kepakaran yang betul. Tidak perlu mencari lagi, pasukan kami akan menguruskan semua kebimbangan marmar anda.",
        },
        {
          image: "/images/icon-4.png",
          alt: "Bahan Berkualiti dan Peralatan yang Tepat",
          p: "Menggunakan bahan dan peralatan pembersihan dan penggilap yang betul adalah satu kemestian untuk menyerlahkan kilauan pada marmar. Jika tidak dilakukan dengan betul ia pasti akan merosakkan marmar selama-lamanya. Menggilap marmar bukan sahaja memerlukan kepakaran yang betul tetapi bahan dan peralatan yang berkualiti. Kami menggunakan produk neutral pH untuk pembersihan dan serbuk berlian asli dicampur dengan mikro-pelelas kristal untuk menggilap marmar yang menghidupkannya semula dan memberikannya kilauan yang tahan lama.",
        },
      ],
      brands: [
        { image: "/images/works/work-1.jpg", alt: "" },
        { image: "/images/works/work-2.jpg", alt: "" },
        { image: "/images/works/work-3.jpg", alt: "" },
        { image: "/images/works/work-4.jpg", alt: "" },
        { image: "/images/works/work-5.jpg", alt: "" },
        { image: "/images/works/work-6.jpg", alt: "" },
        { image: "/images/works/work-7.jpg", alt: "" },
        { image: "/images/works/work-8.jpg", alt: "" },
        { image: "/images/works/work-9.jpg", alt: "" },
        { image: "/images/works/work-10.jpg", alt: "" },
      ],
      project: [
        {
          image: "/images/before-1.jpg",
          alt: "Pembersihan & Menggilap Lantai Marmar",
        },
        { image: "/images/before-2.jpg", alt: "Menggilap Meja Marmar" },
        { image: "/images/before-3.jpg", alt: "Membersih & Menggilap Dinding" },
        { image: "/images/before-4.jpg", alt: "Pembersihan Sofa" },
        { image: "/images/before-5.jpg", alt: "Pembersihan Permaidani" },
        {
          image: "/images/before-6.jpg",
          alt: "Membersih & Menggilap Lantai Jubin Porselin",
        },
        { image: "/images/before-7.jpg", alt: "Menggilap Lantai Granit" },
        { image: "/images/before-8.jpg", alt: "Pembersihan Lantai Jubin Seramik" },
        { image: "/images/before-9.jpg", alt: "Menggilap Lantai Terrazzo" },
        {
          image: "/images/before-10.jpg",
          alt: "Pembersihan Lantai Vinil & Melilin",
        },
        {
          image: "/images/before-11.jpg",
          alt: "Parket dan Penggilap Lantai Kayu",
        },
      ],
    };
  },
};
</script>

<style>
@media (min-width: 768px) {
  #enquire iframe {
    min-height: 1050px !important;
  }
}
</style>