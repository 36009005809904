<template>
  <div>
    <WaBtn />
    <!-- header /main-banner -->
    <MainBanner />

    <!-- Our Services -->
    <div class="pt-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-af-darkblue">
          Our Services
        </h1>
        <div class="w-10 h-1 mx-auto bg-af-darkblue"></div>
        <div class="pt-10 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in service"
            :key="i"
            class="pb-6 md:w-1/2 lg:w-1/3 md:p-1"
          >
            <div class="">
              <img :src="item.image" :alt="item.alt" class="md:mx-auto" />
              <div class="pt-5 md:px-5">
                <h1 class="text-xl font-semibold text-left text-af-darkblue">
                  {{ item.alt }}
                </h1>
                <div class="w-10 h-1 mt-3 bg-af-darkblue"></div>
                <p
                  v-html="item.p"
                  class="py-4 text-sm text-gray-700 lg:text-md md:text-left"
                >
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Why Choose Us -->
    <div class="py-10 bg-blue-50">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-af-dblue">
          Why Active Floor?
        </h1>
        <div class="w-10 h-1 mx-auto bg-af-dblue"></div>
        <div class="pt-10 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in Active_Floor"
            :key="i"
            class="py-1 md:px-1 md:w-1/2 lg:w-1/4"
          >
            <div class="py-6 bg-white rounded-lg boxShadow">
              <img :src="item.image" :alt="item.alt" class="" />
              <div class="md:h-56 lg:h-96 xl:h-80">
                <h1 class="text-lg font-semibold text-center text-af-darkblue">
                  {{ item.alt }}
                </h1>
                <p class="px-2 py-5 text-sm text-center text-gray-700">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Works section -->
    <div class="py-10 bg-af-darkblue">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-white">
          Our Works
        </h1>
        <div class="w-10 h-1 mx-auto bg-white"></div>
        <!-- works section -->
        <div id="works" class="pt-3 lg:py-6">
          <carousel
            :loop="true"
            :autoplay="true"
            :dots="false"
            :autoplaySpeed="3000"
            :autoplayTimeout="3000"
            :responsive="{
              0: { items: 2, nav: false },
              700: { items: 3, nav: false },
              1000: { items: 4, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in brands" :key="i" class="px-1">
              <img :src="item.image" :alt="item.alt" class="rounded-sm" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-af-darkblue">
          Our Past Projects
        </h1>
        <div class="w-10 h-1 mx-auto bg-af-darkblue"></div>
        <!-- <div class="pt-5 lg:w-2/3 lg:mx-auto">
          <Accordion :faq="faq" :focus="false" class="pt-4" />
        </div> -->
        <div class="pt-6 md:flex md:flex-wrap lg:justify-center">
          <div
            v-for="(item, i) in project"
            :key="i"
            class="pt-3 md:w-1/2 md:px-1 md:pt-4 lg:w-1/3"
          >
            <img :src="item.image" :alt="item.alt" />
            <div class="flex justify-between">
              <h1 class="w-1/2 text-sm font-semibold text-center text-gray-900">
                Before
              </h1>
              <h1 class="w-1/2 text-sm font-semibold text-center text-gray-900">
                After
              </h1>
            </div>
            <h1 class="pt-2 text-base font-bold text-af-darkblue">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-client.jpg')"
    >
      <div class="container">
        <h2 class="pb-5 text-5xl font-bold text-center text-white">
          Our Happy Clients
        </h2>
        <div class="w-40 h-1 mx-auto bg-white"></div>
        <div
          class="pt-4 text-lg xl:pl-12 md:text-xl custom md:flex md:text-left md:w-5/6 lg:w-3/4 xl:w-2/3 md:mx-auto"
        >
          <div class="text-white">
            <ul class="list-none list-outside md:pr-4 md:list-disc">
              <li>Hong Leong Investment Bank</li>
              <li>Maybank Berhad</li>
              <li>Capsquare Management Office</li>
              <li>Mutiara Upper East Management Office</li>
              <li>Jolintas Sdn Bhd</li>
              <li>Burgess Rawson Sdn Bhd (M City Residency Jalan Ampang)</li>
              <li>Sutera Kiaramas Mont Kiara Management Office</li>
              <li>Neway Sdn Bhd</li>
            </ul>
          </div>
          <div class="text-white md:pl-4">
            <ul class="list-none list-outside md:list-disc">
              <li>Myeg Sdn Bhd</li>
              <li>Ko Skin</li>
              <li>Ricoh Sdn Bhd</li>
              <li>SHDC Sdn Bhd</li>
              <li>Nirvana Care Sdn Bhd</li>
              <li>Mk Land Saujana Triangle Sdn Bhd</li>
              <li>Shangri-La Hotel KL</li>
              <li>Premier Hotel</li>
              <li>Deleum Bangsar</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10 lg:flex">
      <div class="lg:w-1/2 lg:ml-auto">
        <img src="/images/bg-about.jpg" alt="" class="mx-auto" />
      </div>
      <div class="pt-4 lg:w-1/2 lg:text-left lg:pl-3 lg:pt-0">
        <div class="xl:max-w-xl xl:mr-auto">
          <h1
            class="text-2xl font-semibold text-center lg:pl-3 lg:text-left text-af-darkblue"
          >
            About Us
          </h1>
          <div class="px-2 pt-4 text-sm">
            <p class="pb-2">
              <b
                >Active Floor Maintenance & Reno is the leading floor cleaning,
                polishing, repair, sealing, restoring and maintenance experts in
                Malaysia.</b
              >
              You can depend on us for almost any flooring surface cleaning
              including marble, granite, travertine, flagstone, slate, terrazzo,
              brick, concrete and all-natural stone.
            </p>
            <p class="pb-2">
              <b
                >If you have a marble floor, wall, countertop vanity or shower
                that has become etched, has lost its shine and beauty or is in
                need of repair, then it is probably time to call a professional
                restoration contractor.</b
              >
              We can polish, refinish, repair and completely restore your
              marble, limestone, travertine, saturnia or other natural stone
              back to its original condition.
            </p>
            <p>
              If you have marble with a matte / honed finish, it, too needs
              proper care and maintenance. A marble with a matte / honed finish
              should be periodically cleaned and re-sealed by a professional.
              <br /><br />
              At <b>Active Floor Maintenance & Reno</b> we not only perform
              these services, we can instruct you on the proper care you should
              use in cleaning and maintaining your marble floors and surfaces.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- enquiry form -->
    <div class="py-8 bg-af-darkblue">
      <div class="container">
        <div id="element" class="lg:max-w-3xl lg:mx-auto">
          <div class="pb-4">
            <h1 class="text-3xl font-bold text-center text-white md:text-2xl">
              Send an Enquiry
            </h1>
            <p class="pt-3 text-sm text-white">
              Fill out the form below and we'll get back to you as soon as
              possible
            </p>
          </div>
          <!-- feedback.activamedia.com.sg script begins here -->
          <div id="enquire">
            <iframe
              allowTransparency="true"
              style="min-height: 1000px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5977878.html"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            >
          </div>
          <!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>
    <!-- footer -->
    <!-- <div class="py-8">
      <div class="container">
        <div>
          <img
            src="/images/main-logo.png"
            alt="Active Floor Maintenance & Reno"
            class="w-1/2 mx-auto md:w-1/3 lg:w-52"
          />
          <div class="py-4">
            <p
              class="text-lg font-semibold leading-tight text-center text-af-darkblue"
            >
              25, Jalan Penggawa 28/2, Bandar Mahkota Cheras, Selangor.
            </p>
            <p class="pt-5 text-base font-bold text-center">
              Call Us At :
              <a
                id="call-btn-b1"
                href="tel:+60163330266"
                class="text-gray-900 hover:text-af-darkblue"
                >016-333 0266</a
              >
              |
              <a
                id="call-btn-b2"
                href="https://wa.me/60163330266"
                class="text-gray-900 hover:text-af-darkblue"
                >Whatsapp Us</a
              >
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <footer class="py-8">
      <div class="container flex flex-wrap lg:justify-between">
        <div class="w-full lg:w-1/2 lg:pt-8 lg:pr-4">
          <img
            src="/images/main-logo.png"
            alt="Active Floor Maintenance & Reno"
            class="w-1/2 mx-auto md:w-1/3 lg:w-52"
          />
          <div class="py-4">
            <p
              class="text-lg font-semibold leading-tight text-center text-af-darkblue"
            >
              25, Jalan Penggawa 28/2, Bandar Mahkota Cheras, Selangor.
            </p>
            <p class="pt-5 text-base font-bold text-center">
              Call Us At :
              <a
                id="call-btn-b1"
                href="tel:+60163330266"
                class="text-gray-900 hover:text-af-darkblue"
                >016-333 0266</a
              >
              |
              <a
                id="call-btn-b2"
                href="https://wa.me/60163330266"
                class="text-gray-900 hover:text-af-darkblue"
                >Whatsapp Us</a
              >
            </p>
          </div>
          <div class="md:flex md:justify-center md:items-center">
            <div class="pb-3 md:pb-0 md:w-1/4 lg:w-1/3">
              <img
                src="/images/cibd-malaysia.png"
                alt="CIBD MALAYSIA"
                class="pb-2 mx-auto md:pb-0 w-44 md:w-auto"
              />
              <p class="text-sm leading-tight text-center">Registered Company Under CIDB Malaysia</p>
            </div>
            <div class="md:w-1/4 lg:w-1/3">
              <img
                src="/images/malaysia-logo.png"
                alt="MALAYSIA"
                class="mx-auto w-44 md:w-auto"
              />
              <p class="text-sm leading-tight text-center">Registered Company Under Ministry Of Finance Malaysia</p>
            </div>
          </div>
        </div>
        <div class="w-full py-5 lg:w-1/2">
          <div class="py-4 text-center">
            <h1 class="text-lg font-bold">Our Coverage Areas</h1>
            <div class="md:flex">
              <div class="py-2 md:w-1/2">
                <p class="font-bold">Kuala Lumpur</p>
                <div class="flex justify-center">
                  <div class="w-1/2">
                    <ul>
                      <li>Cheras</li>
                      <li>Pandan Indah</li>
                      <li>Pandan Perdana</li>
                      <li>Pandan Cahaya</li>
                      <li>Pandan Jaya</li>
                      <li>Keramat</li>
                      <li>Danau Kota</li>
                      <li>Setapak</li>
                      <li>Wangsa Maju</li>
                      <li>Taman Melawati</li>
                      <li>Sungai Besi</li>
                      <li>Salak Salatan</li>
                      <li>Petaling Jaya</li>
                      <li>Bangsar</li>
                    </ul>
                  </div>
                  <div class="w-1/2">
                    <ul>
                      <li>Damasara</li>
                      <li>Desa Petaling</li>
                      <li>Gombak</li>
                      <li>Jalan Kuching</li>
                      <li>Jalan Ipoh</li>
                      <li>Jinjang</li>
                      <li>Kepong</li>
                      <li>Kuchai Lama</li>
                      <li>Mont Kiara</li>
                      <li>Old Klang Road</li>
                      <li>OUG</li>
                      <li>Segambut</li>
                      <li>Sentul</li>
                      <li>Serdang</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="py-2 md:w-1/2">
                <p class="font-bold">Selangor</p>
                <div class="flex justify-center">
                  <div class="w-1/2">
                    <ul>
                      <li>Ampang</li>
                      <li>Puchong</li>
                      <li>Subang</li>
                      <li>Subang Jaya</li>
                      <li>Shah Alam</li>
                      <li>Bukit Beruntung</li>
                      <li>Rawang</li>
                      <li>Lembah Jaya</li>
                      <li>Semenyih</li>
                      <li>Kajang</li>
                      <li>Bangi</li>
                      <li>Batang Kali</li>
                      <li>Ulu Yam</li>
                      <li>Cyberjaya</li>
                      <li>Banting</li>
                    </ul>
                  </div>
                  <div class="w-1/2">
                    <ul>
                      <li>Kelana Jaya</li>
                      <li>Kota Kemuning</li>
                      <li>Petaling Jaya</li>
                      <li>Selayang</li>
                      <li>Seri Kembangan</li>
                      <li>USJ</li>
                      <li>Balakong</li>
                      <li>Klang</li>
                      <li>Batu Caves</li>
                      <li>Dengkil</li>
                      <li>Hulu Langat</li>
                      <li>Jenjarom</li>
                    </ul>
                    <p class="pt-3 font-bold">Putrajaya</p>
                    <div class="justify-center">
                      <div>
                        <ul>
                          <li>Putrajaya</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <Copyright />
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import Accordion from "@/components/Accordion.vue";
import MainBanner from "@/components/MainBanner.vue";
import Copyright from "@/components/Copyright.vue";
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    carousel,
    Accordion,
    MainBanner,
    Copyright,
    WaBtn,
  },
  data() {
    return {
      brands: [
        { image: "/images/works/work-1.jpg", alt: "" },
        { image: "/images/works/work-2.jpg", alt: "" },
        { image: "/images/works/work-3.jpg", alt: "" },
        { image: "/images/works/work-4.jpg", alt: "" },
        { image: "/images/works/work-5.jpg", alt: "" },
        { image: "/images/works/work-6.jpg", alt: "" },
        { image: "/images/works/work-7.jpg", alt: "" },
        { image: "/images/works/work-8.jpg", alt: "" },
        { image: "/images/works/work-9.jpg", alt: "" },
        { image: "/images/works/work-10.jpg", alt: "" },
      ],
      Active_Floor: [
        {
          image: "/images/icon-1.png",
          alt: "Over 2 Decades Of Experience",
          p: "With over 2 decades of experience in the cleaning and disinfection industry, we understand that both processes require constant improvements and innovations to meet the ever-changing needs of our customers and the environment we live in",
        },
        {
          image: "/images/icon-2.png",
          alt: "Reasonable Pricing, No Compromise on Quality",
          p: "Our skilled Technicians are all with more than 20 years of experience with all types of polishing works",
        },
        {
          image: "/images/icon-3.png",
          alt: "Trained Specialists ",
          p: "We have a team of well-trained marble polishing experts who have never failed to impress our clients with their skills as well as knowledge about the process. Their work is top-notch and due to their years of experience, they understand marble in and out. The marble polishing process is a meticulous process and requires to be handled by only professionals with the right expertise. Look no further, our team will take care of all your marble concerns.",
        },
        {
          image: "/images/icon-4.png",
          alt: "Quality Material and Right Equipment ",
          p: "Using the right cleaning and polishing material and equipment is a must to bring out the shine in the marble. If not done right it will for sure damage the marble forever. Marble polishing not only requires the right expertise but quality material and equipment. We use pH neutral products for cleaning and real diamond powder mixed with crystalline micro-abrasives to polish the marble which brings it back to life and gives it long-lasting shine.",
        },
      ],
      service: [
        {
          image: "/images/service-1.jpg",
          alt: "Floor Polishing & Restoration",
          p: "Marble, Granite, Porcelain Tile,Rubber Floor,Vinyl Floor , Wood Floor, Parquet, Wood Decking, Cement Flooring",
        },
        {
          image: "/images/service-2.jpg",
          order: "order-2",
          alt: "Polish Table /Counter Top",
          p: "Marble, Granite, Solid Surface, Wood Table",
        },
        {
          image: "/images/service-3.jpg",
          alt: "Floor Cleaning",
          p: "Outdoor Tile, Ceramic Tile, Marble Floor, Epoxy Floor, Carpet Floor",
        },
        {
          image: "/images/service-4.jpg",
          alt: "Sofa Cleaning",
          order: "order-2",
          p: "Leather & Fabric",
        },
        {
          image: "/images/service-5.jpg",
          alt: "Repair Flooring",
          order: "order-2",
          p: "- Chipped Marble, Rotten Wood Floor, Scratches, Water Damaged etc.",
        },
        {
          image: "/images/service-6.jpg",
          alt: "Installation",
          order: "order-2",
          p: "Parquet, Wood Floor, Solid Wood Decking - Chengal, Balau, Merbau Wood, Teak Wood, White Oak etc.<br> Variety Design - Classic Horizontal Strips, Diagonal, Brick Wood or Herringbone Pattens",
        },
        {
          image: "/images/service-7.jpg",
          alt: "Wall Cleaning and Polishing",
          order: "order-2",
          p: "All Types Of Wall",
        },
        {
          image: "/images/service-8.jpg",
          alt: "Outdoor Floor Cleaning",
          order: "order-2",
          p: "Patio Driveway Cleaning",
        },
      ],
      project: [
        {
          image: "/images/before-1.jpg",
          alt: "Marble Floor Cleaning & Polishing",
        },
        { image: "/images/before-2.jpg", alt: "Marble Table Polishing " },
        { image: "/images/before-3.jpg", alt: "Wall Cleaning & Polishing" },
        { image: "/images/before-4.jpg", alt: "Sofa Cleaning " },
        { image: "/images/before-5.jpg", alt: "Carpet Cleaning " },
        {
          image: "/images/before-6.jpg",
          alt: "Porcelain Tile Floor Cleaning & Polishing",
        },
        { image: "/images/before-7.jpg", alt: "Granite Floor Polishing" },
        { image: "/images/before-8.jpg", alt: "Ceramic Tile Floor Cleaning" },
        { image: "/images/before-9.jpg", alt: "Terrazzo Floor Polishing" },
        {
          image: "/images/before-10.jpg",
          alt: "Vinyl Floor Cleaning & Waxing",
        },
        {
          image: "/images/before-11.jpg",
          alt: "Parquet and Wood Floor Polishing",
        },
      ],
    };
  },
};
</script>

<style>
#works .owl-carousel .owl-stage {
  transition-timing-function: linear !important;
}
.txt-shadow {
  text-shadow: 2px 2px 2px rgb(31, 31, 31);
}
@media (min-width: 768px) {
  #enquire iframe {
    min-height: 1050px !important;
  }
}
.custom li {
  font-family: "Oswald", sans-serif;
}
</style>