<template>
  <div class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs font-medium text-center text-white md:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>