<template>
  <div class="w-11/12 mx-auto lg:w-full">
    
    <div v-for="(item, index) in faq" :key="index" class="mb-1">
      <div class="flex flex-wrap items-center px-2 py-2 font-medium text-left text-white rounded-md cursor-pointer bg-af-dblue" @click="makeActive(index)" ref="question">
        <p class="flex-1 text-base font-semibold text-white">{{ item.q }}</p>
        <div class="px-2 text-2xl transition duration-200 delay-200 transform" :class="[ qIndex === index ? 'rotate-45 ' : '' ]">
          +
        </div>
      </div>

      <collapse-transition>
        <div v-show="qIndex === index ? true : false" class="px-2 pb-5 text-base text-gray-900 transition duration-500 ease-in-out transform border-b-2 lg:text-xs">
          <div class="flex justify-between">
          <h1 class="w-1/2 text-base font-bold text-center text-gray-900">Before</h1>
          <h1 class="w-1/2 text-base font-bold text-center text-gray-900">After</h1>
          </div>
          <img :src="item.image" :alt="item.alt" class="mx-auto">
        </div>
      </collapse-transition>

    </div>
  </div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition'
export default {
  name: 'Accordion',
  props: {
    faq: {
      type: Array,
      required: true
    },
    focus: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CollapseTransition
  },
  data() {
    return {
      qIndex: -1,
    }
  },
  methods: {
    makeActive(index) {
       index === this.qIndex ? this.qIndex = -1 : this.qIndex = index
       if (this.focus) this.$scrollTo(this.$refs.question[index], 500, {offset: -80})
    }
  }
}
</script>

<style>
/* Border Style */
/* .borderCustom{border: 2px solid #c3a08d;}
.borderCustom:last-child{border: none;} */
</style>