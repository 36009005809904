<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <!-- language selection  -->
      <div class="text-right text-white txt-shadow">
        <h1>
          <a href="/" class="hover:text-af-darkblue">English</a> |
          <a href="/bm" class="hover:text-af-darkblue">Bahasa Melayu</a>
        </h1>
      </div>
      <div class="py-28 lg:py-40">
        <img
          src="/images/main-logo-white.png"
          alt="Piano Warehouse"
          class="w-1/2 mx-auto lg:w-1/6 md:w-1/3"
        />
        <div class="pt-4">
          <h1
            class="text-4xl font-bold leading-none text-white uppercase txt-shadow lg:pb-5 md:text-6xl"
          >
            Pusat No.1 Anda
          </h1>
          <h2 class="pt-4 text-2xl italic text-white lg:text-5xl md:text-4xl">
            Untuk Semua Keperluan Lantai di Malaysia
          </h2>
          <h1 class="pt-1 text-base leading-tight text-white md:text-xl">
            Kami Menyediakan Konsultasi Profesional Percuma
          </h1>
        </div>

        <!-- cta button -->
        <div class="pt-6 md:flex md:justify-center">
          <a
            v-if="!hideEnquiry"
            href="#"
            v-scroll-to="{ el: '#element', duration: 1500 }"
            class="block py-2 mx-auto mb-1 text-base font-semibold text-center uppercase border md:mb-0 hover:text-white hover:bg-af-blue text-af-blue md:mr-2 w-72 border-af-blue md:mx-0"
            >Tanya Kami</a
          >
          <a
            id="call-btn-banner"
            href="tel:+60163330266"
            class="block py-2 mx-auto mb-1 text-base font-semibold text-center uppercase border hover:text-white hover:bg-af-blue md:mr-2 md:mb-0 text-af-blue w-72 border-af-blue md:mx-0"
            >Hubungi Kami : 016-3330266</a
          >
          <a
            id="wa-btn-banner"
            href="http://www.activefloor.com.my/"
            class="block py-2 mx-auto text-base font-semibold text-center uppercase border hover:text-white hover:bg-af-blue text-af-blue w-72 border-af-blue md:mx-0"
            >Laman Web</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>